<template>
    <div class="col-12 d-flex product-details-border position-relative pe-0">
        <div class="row w-100 pe-lg-0 me-1 py-2">
          <div class="col-md-4 col-12">
            <div class="mb-1">
              <label class="form-label" for="product_id">Product</label>
              <AsyncSelect
                  :api-service="fetchProductList"
                  placeholder="Select Product"
                  v-model="product"
                  label="text"
                  :format-label="option => option.text"
                  :additional-query="additionalProductQuery"
                  ref="productField"
              />
            </div>
            <span class="text-danger" v-if="productError">{{ productError }}</span>
          </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="quantity">Quantity</label>
                    <input v-model="data.quantity" name="quantity" type="number" class="form-control text-right"
                        ref="qtyField" @keyup="validateQty"  @keyup.enter="handleAddProduct" />
                    <span class="text-danger" v-if="quantityError && quantityError !== ''">{{ quantityError }}</span>
                </div>
            </div>
            <div class="col-md-2 col-12 mt-2">
                <div class="mb-1">
                    <button class="btn btn-primary btn-block waves-effect waves-float waves-light"
                        @click="handleAddProduct">Add</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref, onMounted } from "@vue/runtime-core";
import {inject, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleInventory from '@/services/modules/inventory'
import handlePurchaseAndSales from "@/services/modules/purchase";
import { useRoute } from "vue-router";
import { floor, isEmpty, trim } from "lodash";

let loading = ref(false)
const formData = inject("formData");

const singleData = {
    product: null,
    product_name: '',
    product_id: null,
    brand: null,
    brand_name: '',
    brand_id: null,
    color: null,
    color_name: '',
    color_id: null,
    size: null,
    size_name: '',
    size_id: null,
    item_full_name: '',
    unit: null,
    rate: null,
    quantity: null,
    amount: 0.00,
    discount_amount: 0,
    discount_percent: 0,
    vat: 0,
    vat_amount:0,
    delivery_date: null,
    style: '',
    po_number: '',
    description: '',
    offer_discount:0,
    free_quantity:0,
    stock:0,
    sku:'',
}

const route = useRoute();
const { fetchProductStock, fetchProductList, fetchFreeQuantity } = handlePurchaseAndSales()
const $emit = defineEmits(['handleProductAdd'])
const quantityError = ref('')
const product = ref('')
const productError = ref('')
const qtyField = ref(null)
const productField = ref(null)
const updateIndex = ref(null)
const data = ref({ ...singleData })
const { ...rest } = handleInventory()
const companyQuery = `?company_id=${route.params.companyId}`;

const companyId = computed(() => {
    return route.params.companyId
})

const fullNameCreate = computed(() => {
    return ''
})
const totalAmount = computed(() => {
    return (data.value.rate * data.value.quantity).toFixed(2);
})
const additionalProductQuery = {
  product_types:  ['finished_goods', 'services'],
}

const selectedProduct = computed(() => product.value)

watch(fullNameCreate, (fullName) => {
    data.value.item_full_name = fullName;
})

watch(totalAmount, (newValue) => {
    data.value.amount = newValue
})

watch(selectedProduct, (newValue) => {
  getFreeQuantity();
  if (newValue && newValue.id) {
        fetchProductStock(newValue.id, `${companyQuery}&skip_stock_check=1`).then((res) => {
            if (res.data) {
                data.value.product_id = newValue.id;
                data.value.rate = res.data.sales_rate ? res.data.sales_rate : 0;
                data.value.product_name = newValue.text;
                data.value.sku = res.data.sku;
                data.value.stock = res.data.stock;
                return;
            }
            data.value.product_id = null;
            data.value.rate = 0;
            data.value.product_name = "";
            data.value.sku = "";
            data.value.stock = "";
        })
            .catch(() => { })
    }
})


const validateData = () => {
    quantityError.value = ""
    productError.value = ""

    let errorsFound = false;

    if (!product.value.id) {
        productError.value = "Product is required"
        errorsFound = true;

        focusProductSelect()
    }

    if (!validateQty()) {
        errorsFound = true;
    }
    if (!data.value.quantity || data.value.quantity <= 0) {
        quantityError.value = "Quantity must be more than 0"
        errorsFound = true;
    }

    return errorsFound;
}

const validateQty = () => {
    let quantity = Number(data.value.quantity)

    if (!Number.isInteger(quantity)) {
        quantityError.value = "The quantity must be an Integer";
        data.value.quantity = floor(data.value.quantity)
        qtyField.value.focus();
        return false;
    }
    loading.value = true
    getFreeQuantity();

    return true;
}

const getFreeQuantity = () => {
  const queryObject = Object.assign({}, {
    company_id: route.params.companyId,
    quantity: data.value.quantity
  })

  fetchFreeQuantity(selectedProduct.value.id, `?${new URLSearchParams(queryObject).toString()}`)
      .then(res => {
        if (!res.data) {
          data.value.free_quantity = 0;
          data.value.offer_discount = 0;
        }

        data.value.free_quantity = res.data.free_quantity;
        data.value.offer_discount = res.data.discount_amount;
      }).catch((e) => {
        data.value.free_quantity = 0;
        data.value.offer_discount = 0;
  }).finally(() => {
    loading.value = false
  });
}


const handleAddProduct = () => {
    if (validateData()) {
        return;
    }
    $emit('handleProductAdd', {...data.value}, updateIndex.value);
    data.value = { ...singleData };
    product.value = '';


    if (updateIndex.value !== null && updateIndex.value !== undefined) {
        updateIndex.value = null;
    }
    focusProductSelect();
}

const focusProductSelect = () => {
  productField.value.dropdownRef.searchEl.focus()
}
const setData = (object) => {
    if (object) {
        data.value = { ...object };
    }
}

onMounted(() => {
    loading.value = true

})

defineExpose({ updateIndex, setData })
</script>
